.fainin-button {
    padding: 4px 16px 4px 16px !important;
    height: 42px; // 8px y padding is added = 50px total
    border: none;
    font-weight: 500 !important;

    display: flex;
    justify-content: center;
    align-items: center;

    &.left {
        text-align: left;
        align-items: flex-start;
    }

    &.outline {
        border-width: 2px;
        border-style: solid;
    }

    &.primary {
        border-color: var(--primary);
        color: var(--primary);

        &:not(.outline) {
            background: var(--primary);
            color: var(--light);
        }
    }

    &.secondary {
        border-color: var(--secondary);
        color: var(--secondary);

        &:not(.outline) {
            background: var(--secondary);
            color: var(--light);
        }
    }

    &.accent {
        border-color: var(--accent);
        color: var(--accent);

        &:not(.outline) {
            background: var(--accent);
            color: var(--dark);
        }
    }

    &.light {
        background: none;
        border-color: var(--light);
        color: var(--light);

        &:not(.outline) {
            background: var(--light);
            color: var(--dark);
        }
    }
}
