@import "variables";

// Spacing
.spacing {
    @each $directionShort, $direction in $directions {
        &-m#{$directionShort} {
            @each $spacingName, $spacing in $spaces {
                &-#{$spacingName} {
                    margin-#{$direction}: $spacing !important;
                }
            }
        }
    }
}
