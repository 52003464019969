@import "variables";



// Text-styles (Still used somewhere)
@each $title-font-style-name, $title-font-style-map in $title-font-styles-mobile {
    #{$title-font-style-name} {
        @include text-properties($title-font-style-map);
        font-family: 'Barlow', serif;
    }

    .text-#{$title-font-style-name},
    .title-#{$title-font-style-name} {
        @include text-properties($title-font-style-map);
        font-family: 'Barlow', serif;
    }
}

@media screen and (min-width: $media-breakpoint-typography) {
    @each $title-font-style-name, $title-font-style-map in $title-font-styles {
        #{$title-font-style-name} {
            @include text-properties($title-font-style-map);
            font-family: 'Barlow', serif;
        }

        .text-#{$title-font-style-name},
        .title-#{$title-font-style-name} {
            @include text-properties($title-font-style-map);

            font-family: 'Barlow', serif;
        }
    }
}

.text-subline {
    @each $subline-font-style-name, $subline-font-style-map in $subline-font-styles-mobile {
        &-#{$subline-font-style-name} {
            @include text-properties($subline-font-style-map);

            font-family: 'Roboto', serif;
        }
    }

    @media screen and (min-width: $media-breakpoint-typography) {
        @each $subline-font-style-name, $subline-font-style-map in $subline-font-styles {
            &-#{$subline-font-style-name} {
                @include text-properties($subline-font-style-map);

                font-family: 'Roboto', serif;
            }
        }
    }
}

.paragraph {
    @each $paragraph-font-style-name, $paragraph-font-style-map in $paragraph-font-styles-mobile {
        &-#{$paragraph-font-style-name} {
            @include text-properties($paragraph-font-style-map);

            font-family: 'Roboto', serif;
        }
    }

    @media screen and (min-width: $media-breakpoint-typography) {
        @each $paragraph-font-style-name, $paragraph-font-style-map in $paragraph-font-styles {
            &-#{$paragraph-font-style-name} {
                @include text-properties($paragraph-font-style-map);

                font-family: 'Roboto', serif;
            }
        }
    }
}

.text-display {
    @include text-properties(map-get($display-font-styles-mobile, 'display'));

    @media screen and (min-width: $media-breakpoint-typography) {
        @include text-properties(map-get($display-font-styles-mobile, 'display'));
    }
}
