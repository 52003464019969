@import "variables";

.auth {

    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    line-height: 21px;
    min-width: 0;

    text-rendering: optimizelegibility;
    -webkit-tap-highlight-color: transparent;

    @include media-breakpoint-up(md) {
        flex-direction: column;
    }


    &-form {
        max-width: 30rem;

        color: var(--text-color);
        background-color: white;

        padding: 2rem;
        margin: 2%;
        @include media-breakpoint-up(md) {
            padding: 4rem;
            margin: unset;
        }

        &-title {
            font-size: $h3-font-size;
            font-weight: 600;
            color: var(--text-color);
            margin-bottom: .75rem;
        }

        &-subtitle {
            font-size: $font-size-base;
            color: var(--text-color);
            margin-bottom: 1rem;
        }

        &-action-bar {
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }



    }

}
