@import "variables";

$link-colors: (
    "default": $text-muted,
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "accent": $accent,
    "light": $light,
    "dark": $dark
);


@each $i, $color in $link-colors {

    .link-#{$i} {
        color: $color;
        font-weight: 600;

        a {
            color: $color;
            font-weight: 600;
        }

        @include hover {
            color: darken($color, 10%);
            a {
                color: darken($color, 10%);
            }

        }

    }
}

