// Bootstrap overrides
$enable-grid-classes: true;
$primary: #5BCBCE;
$secondary: #00AEAC;

// status colors
$success: #01AD83;
$danger: #D54A41;
$info: #00B7E0;
$warning: #FFA500;
$light-gray: #D1D5DB;
$light: #fbfbfb;
$medium-gray: #8D8D8D;
$gray: #707070;
$dark-gray: #646464;
$dark: #3B3B3B;

$enable-gradients: false;
$spacer: 1.2rem;
$btn-border-radius: 12px;
$border-radius: 12px;

$breadcrumb-bg: white !default;
$breadcrumb-divider: "›" !default;
$breadcrumb-margin-bottom: 0 !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1260px
);

//@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
$font-family-base: 'Roboto';
$font-size-base: 1rem;
$h1-font-size: 48px;

body {
    font-family: Roboto, 'Open Sans', serif;
    font-size: 16px;
    overflow-y: scroll; // fix for not scrolling when navigating from differnt pages (found when navigating from product page to welcome-to-fainin

    p {
        font-family: Roboto, 'Open Sans', serif;
    }
    .ps {
        font-size: 14px;
    }
    .pxs {
        font-size: 12px;
    }
}
//@import url(https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed&display=swap);
$headings-font-family: 'Barlow';

@import "variables";

@import "bootstrap/scss/alert";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/card";
@import "bootstrap/scss/close";
@import "bootstrap/scss/code";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/images";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/media";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/list-group"; //< used for tabs in account dashboard
@import "bootstrap/scss/tables";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/type";
@import "bootstrap/scss/root";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/spinners";













