@import "variables";

$spacer-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "accent": $accent,
    "light": $light,
    "dark": $dark
);

@each $i, $color in $spacer-colors {
    .spacer-#{$i} {
        border-radius: 0;
        border: none;
        width: 50px;
        height: 7px;
        position: relative;
        margin-bottom: 15px;
        margin-top: 15px;
        background: $color;
        display: block;
    }

}
