@import "variables";

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "accent": $accent,
    "light": $light,
    "dark": $dark,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
    "gray-light": $light-gray,
    "gray": $gray,
    "gray-dark": $dark-gray,
    "gray-medium": $medium-gray
);

$buttons: (
    "primary": $primary,
    "secondary": $secondary,
    "accent": $accent,
    "light": $light,
    "default": $light-bg,
    "dark": $dark,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
    "disabled": $light-gray
);

$pulse: (
    "primary": $primary,
    "secondary": $secondary,
    "accent": $accent,
    "light": $light,
    "dark": $dark,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
);

@each $i, $color in $colors {
    .text-#{$i} {
        color: $color !important;
        svg {
            fill: $color;
        }
    }
}

@each $i, $button in $buttons {
    .btn-#{$i} {
        @include button-variant($button, $button);
    }

    .btn-outline-#{$i} {
        @include button-outline-variant($button);
    }
}

@each $i, $color in $pulse {
    .pulse-#{$i} {
        box-shadow: 0 0 0 0 rgba($color, 1);
        transform: scale(1);
        animation: pulse-#{$i} 2s infinite;
    }

    @keyframes pulse-#{$i} {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba($color, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba($color, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba($color, 0);
        }
    }
}
