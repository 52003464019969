@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "mixins";

$primary: #5BCBCE;
$secondary: #00AEAC;
$tertiary: #40B9BC; //< new
$accent: #EFCD20; //< new

$text-color: #303030;
$light-bg: #eefafa;

// status colors
$success: #01AD83;
$danger: #D54A41;
$info: #00B7E0;
$warning: #FFA500;
$light: #fbfbfb;
$light-gray: #D2D2D2;
$light-gray-bg: #F3F4F6;
$medium-gray: #8D8D8D;
$gray: #707070;
$dark-gray: #646464;
$dark: #3B3B3B;

$directions: (
    t: top ,
    r: right,
    b: bottom,
    l: left
);

$spaces: (
    tiny: 8px,
    small: 16px,
    base: 24px,
    large: 32px,
    x-large: 48px,
    xx-large: 64px
);


$overlays: (
    elevate: 10,
    subheader: 98,
    header: 100,
    tabbar: 103,
    actionsheet: 104,
    menudrawer: 105,
    modal: 106,
    popover: 107,
    tooltip: 108,
    cdkOverlay: 1000 // the maximum!!
);



// Media breakpoint to switch text styles (mobile and desktop)
$media-breakpoint-typography: 768px;

$display-font-styles: (
    'display': (
        'fontsize': 47.78px,
        'lineheight': 130%,
        'fontweight': 600
    ),
);

$display-font-styles-mobile: (
    'display': (
        'fontsize': 39.81px,
        'lineheight': 130%,
        'fontweight': 600
    ),
);

// Titles
$title-font-styles: (
    'h1': (
        'fontsize': 39.81px,
        'lineheight': 130%,
        'fontweight': 600
    ),
    'h2': (
        'fontsize': 33.18px,
        'lineheight': 130%,
        'fontweight': 600
    ),
    'h3': (
        'fontsize': 27.65px,
        'lineheight': 130%,
        'fontweight': 600
    ),
    'h4': (
        'fontsize': 23.04px,
        'lineheight': 130%,
        'fontweight': 600
    ),
    'h5': (
        'fontsize': 19.20px,
        'lineheight': 130%,
        'fontweight': 600
    )
);

$title-font-styles-mobile: (
    'h1': (
        'fontsize': 33.18px,
        'lineheight': 130%,
        'fontweight': 600
    ),
    'h2': (
        'fontsize': 27.65px,
        'lineheight': 130%,
        'fontweight': 600
    ),
    'h3': (
        'fontsize': 23.04px,
        'lineheight': 130%,
        'fontweight': 600
    ),
    'h4': (
        'fontsize': 19.20px,
        'lineheight': 130%,
        'fontweight': 600
    ),
    'h5': (
        'fontsize': 16px,
        'lineheight': 130%,
        'fontweight': 600
    )
);

// Subline
$subline-font-styles: (
    'semi-bold': (
        'fontsize': 19.20px,
        'lineheight': 24px,
        'fontweight': 600
    )
);

$subline-font-styles-mobile: (
    'semi-bold': (
        'fontsize': 19.20px,
        'lineheight': 24px,
        'fontweight': 600
    )
);


// Paragraph
$paragraph-font-styles: (
    'large': (
        'fontsize': 19.20px,
        'lineheight': 24px,
        'fontweight': 400
    ),
    'regular': (
        'fontsize': 16px,
        'lineheight': 24px,
        'fontweight': 400
    ),
    'bold': (
        'fontsize': 16px,
        'lineheight': 24px,
        'fontweight': 800
    ),
    'small': (
        'fontsize': 13.33px,
        'lineheight': 16px,
        'fontweight': 400
    ),
    'xsmall': (
        'fontsize': 11.11px,
        'lineheight': 16px,
        'fontweight': 600
    )
);

$paragraph-font-styles-mobile: (
    'large': (
        'fontsize': 19.20px,
        'lineheight': 24px,
        'fontweight': 400
    ),
    'regular': (
        'fontsize': 16px,
        'lineheight': 24px,
        'fontweight': 400
    ),
    'bold': (
        'fontsize': 16px,
        'lineheight': 24px,
        'fontweight': 800
    ),
    'small': (
        'fontsize': 13.33px,
        'lineheight': 16px,
        'fontweight': 400
    ),
    'xsmall': (
        'fontsize': 11.11px,
        'lineheight': 16px,
        'fontweight': 600
    )
);



