
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #00aeac;
@info-color: @primary-color;
@success-color: #01AD83;
@processing-color: #34d3d3;
@error-color: #D54A41;
@highlight-color: #EFCD20;
@warning-color: #D47E43;
@normal-color: #d9d9d9;
@white: #fbfbfb;

@heading-color: #303030;
@text-color: #303030;

@border-radius-base: 0.75rem;
@dropdown-edge-child-vertical-padding: 1rem;
//@dropdown-edge-child-padding: 1rem 10rem 1rem 0;
@item-width: 18.75rem;

@breadcrumb-link-color: #707070;
@breadcrumb-link-color-hover: #00AEAC;

b, strong {
  font-weight: 600;
}
