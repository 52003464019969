@import "variables";

:root {
    --collection-card-height: 192px;
    --collection-card-width: 175px;
}

.collection-card {


    &.translucent {
        height: var(--collection-card-height);
        width: var(--collection-card-width);

        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(25px);
        border-radius: 8px;

        display: flex;
        flex-direction: column;


        margin: 2rem 2rem 0;
        z-index: 2;

        .collection-image {
            justify-self: flex-start;
            overflow: visible;
            //margin: -1rem -1rem 0;
            transform: scale(1.05) translateY(-24px);
            transform-origin: center center;
            z-index: 1;
        }

        .collection-name {
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
            justify-self: flex-end;
            position: absolute;
            bottom: 0;
            z-index: 2;
            height: 40px;
            width: 100%;
            background: var(--secondary);
            backdrop-filter: blur(27px);
            border-radius: 8px;
            line-height: 1rem;

            display: flex;
            justify-content: center;
            align-items: center;

            color: var(--light);
            font-family: "Barlow", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
        }
    }
}
