@import "variables";

$item-border-radius: 4px;
$item-height: 220px;
$item-info-height: 110px;

.featured-items {
    display: grid;
    grid-gap: 16px;

    @for $i from 0 through 5 {
        .item-#{$i} {
            grid-area: Item#{$i};
            position: relative;

            transition: box-shadow 0.3s, border-color 0.3s;

            &:hover {
                border-color: transparent;
                box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
            }

            &.clickable {
                cursor: pointer;
            }

            .item-image-wrapper {
                height: 100%;

                .item-image {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }

                &::after {
                    display: block;
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    content: '';
                    opacity: 0.85;
                    background: linear-gradient(0deg, rgba(43, 182, 183, 1) 0%, rgba(43, 182, 183, 1) 35%, rgba(193, 233, 234, 0.000001) 55%, transparent 56%);;
                }
            }

            .item-description {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                height: $item-info-height;
                color: var(--light);
                padding: 4px 16px;

                .title {
                    font-size: $h4-font-size;
                    font-family: Barlow;
                    font-style: normal;
                    font-weight: bold;
                }

                .slots {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .slot {
                        padding-right: 1rem;

                        .big {
                            font-size: $h5-font-size;
                            font-family: 'Barlow';
                            font-weight: bold;
                        }
                    }
                }
            }

            .item-category,
            .item-sub-category {
                display: flex;
                align-items: center;

                position: absolute;
                top: 4px;
                left: 4px;
                height: 32px;

                font-family: 'Barlow';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;

                color: var(--light);
                background: var(--primary);
                padding: 8px 12px;
                border-radius: 4px;
            }

            .item-sub-category {
                top: 40px;
                height: 26px;
                font-size: 12px;
                color: var(--light);
                background: var(--secondary);
            }
        }

    }


    /*
     * Layout for 3 items for border radius
     */
    &-3 {
        grid-template-areas:
        "Item0"
        "Item1"
        "Item2";
        grid-template-rows: 220px 220px 220px;

        @include media-breakpoint-up(md) {
            grid-template-areas:
        "Item0 Item0 Item1"
        "Item0 Item0 Item2";
            grid-template-rows: $item-height $item-height;
        }

        .item-0 {
            border-top-left-radius: $item-border-radius;
            border-bottom-left-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-top-left-radius: $item-border-radius;
                border-bottom-left-radius: $item-border-radius;
            }
        }

        .item-1 {
            border-top-right-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-top-right-radius: $item-border-radius;
            }
        }

        .item-2 {
            border-bottom-right-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-bottom-right-radius: $item-border-radius;
            }
        }
    }

    &-5 {
        grid-template-areas:
        "Item0"
        "Item1"
        "Item2"
        "Item3"
        "Item4";
        grid-template-rows: 220px 220px 220px;

        @include media-breakpoint-up(md) {
            grid-template-areas:
        "Item0 Item1"
        "Item0 Item2"
        "Item3 Item4";
            grid-template-rows: $item-height $item-height $item-height;
        }

        .item-0 {
            border-top-left-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-top-left-radius: $item-border-radius;
            }
        }

        .item-1 {
            border-top-right-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-top-right-radius: $item-border-radius;
            }
        }

        .item-4 {
            border-bottom-left-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-bottom-left-radius: $item-border-radius;
            }
        }

        .item-5 {
            border-bottom-right-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-bottom-right-radius: $item-border-radius;
            }
        }
    }

    &-6 {
        grid-template-areas:
        "Item0"
        "Item1"
        "Item2";
        grid-template-rows: 220px 220px 220px;

        @include media-breakpoint-up(md) {
            grid-template-areas:
        "Item0 Item0 Item1"
        "Item0 Item0 Item2"
        "Item3 Item5 Item5"
        "Item4 Item5 Item5";
            grid-template-rows: $item-height $item-height $item-height $item-height;
        }

        .item-0 {
            border-top-left-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-top-left-radius: $item-border-radius;
            }
        }

        .item-1 {
            border-top-right-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-top-right-radius: $item-border-radius;
            }
        }

        .item-4 {
            border-bottom-left-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-bottom-left-radius: $item-border-radius;
            }
        }

        .item-5 {
            border-bottom-right-radius: $item-border-radius;

            .item-image,
            .item-image-wrapper,
            .item-image-wrapper::after {
                border-bottom-right-radius: $item-border-radius;
            }
        }
    }

}
