// This is a mixin function to make the migration to the new math.div
// function more easy in the future
@function divide($a, $b) {
    @return $a / $b;
}

/**
* Selects a map based on the given name.
* Available maps: title, subline, paragraph.
*
* @param {String} $map-name - The name of the map to select
* @return {Map} - The map that was selected
 */
@function select-map($map-name) {
    @if $map-name == 'title' or $map-name == 'text' {
        @return [$title-font-styles, $title-font-styles-mobile];
    } @else if $map-name == 'subline' {
        @return [$subline-font-styles, $subline-font-styles-mobile];
    } @else if $map-name == 'paragraph' or $map-name == 'p' {
        @return [$paragraph-font-styles, $paragraph-font-styles-mobile];
    } @else if $map-name == 'display' {
        @return [$display-font-styles, $display-font-styles-mobile];
    }@else {
        @error "Unknown map name: #{$map-name}.";
    }
}

/**
 * Available maps: title, subline, paragraph.
 * @param {String} $map-name - The name of the map to select
 * @param {String} $key - The key of the value for the selected map
 */
@mixin typography($map-name, $key) {
    $maps: select-map($map-name);
    $map-desktop: nth($maps, 1);

    @include text-properties(map-get($map-desktop, $key));
}

/**
 * Available maps: title, subline, paragraph.
 * @param {String} $map-name - The name of the map to select
 * @param {String} $key - The key of the value for the selected map
 */
@mixin typography-mobile($map-name, $key) {
    $maps: select-map($map-name);
    $map-mobile: nth($maps, 2);

    @include text-properties(map-get($map-mobile, $key));
}

@mixin text-properties($font-style-map) {
    font-size: map-get($font-style-map, 'fontsize') !important;
    line-height: map-get($font-style-map, 'lineheight') !important;
    font-weight: map-get($font-style-map, 'fontweight');
}


/**
 * Available maps: title, subline, paragraph.
 * @param {String} $map-name - The name of the map to select
 * @param {String} $key - The key of the value for the selected map
 */
@mixin title($key) {
    $maps: select-map('title');
    $map-desktop: nth($maps, 1);
    $map-mobile: nth($maps, 2);

    @include text-properties(map-get($map-mobile, $key));
    @include media-breakpoint-up(md) {
        @include text-properties(map-get($map-desktop, $key));
    }
}

/**
 * New input style for search filter components
 */
@mixin base-input-style($fixed-height: true) {
    transition: all ease-in-out 0.3s; // animate width transition
    @if $fixed-height {
        height: 42px;
    }
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--light-gray);
    background-color: var(--light-gray-bg);
    color: var(--gray);
    font-weight: 400;
    line-height: 1.5; // Adjust line-height to ensure text visibility

    outline: none; // Remove default outline
    &:focus {
        border-color: var(--primary);
        box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
    }

    &[readonly] {
        background-color: var(--gray-medium);
        pointer-events: none;
    }
}

/**
 * Return the higher value between two values
 */
@function max-value($value1, $value2) {
    @return if($value1 > $value2, $value1, $value2);
}
