@import "variables";

:root {
    --nz-custom-skeleton-image-width: 96px;
    --nz-custom-skeleton-image-height: 96px;
}

nz-skeleton-element.custom-size {
    width: var(--nz-custom-skeleton-image-width);
    height: var(--nz-custom-skeleton-image-height);

    .ant-skeleton-image {
        width: var(--nz-custom-skeleton-image-width);
        height: var(--nz-custom-skeleton-image-height);
    }
}


.fainin-ant-select,
.fainin-ant-dropdown {

    .ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background: var(--light);
        height: 48px;
        border: 1px solid var(--light-gray);
        border-radius: 12px;
        font-weight: 400;
        font-size: 16px;
        align-items: center;
    }

    .ant-select-multiple .ant-select-selection-item,
    .ant-select-single .ant-select-selection-item,
    .ant-select-selection-placeholder {
        height: 2rem;
        align-self: center;
        align-items: center;
    }

    .ant-select-selection-search {
        height: 2rem;
        align-self: center;
    }

    .ant-select.ng-star-inserted {
        border: none;
        width: 100%;
    }
}

.fainin-nz-form-item {
    width: 100%;
    margin: 0;

    &.auto-width {
        max-width: 100%;
        width: auto !important;

       .ant-form-item-control {
           max-width: 90%;
           width: auto !important;
           flex: auto;
       }
    }

    .ant-input {
        border-radius: 10px;
        background-color: var(--light);
        margin-bottom: 0.5rem;
        min-height: 50px;
    }
}


input.no-default-focus {
    &:focus,
    &:focus-visible {
        outline: none;
    }
}

/*
 * Stats
 */
.ant-statistic-title {
    color: var(--gray-medium);
    @include media-breakpoint-up(md) {
        font-size: map-get(map-get($paragraph-font-styles,'small'),'fontsize');
        line-height: map-get(map-get($paragraph-font-styles,'small'),'lineheight');
        font-weight: map-get(map-get($paragraph-font-styles,'small'),'fontweight');
    }
    font-size: map-get(map-get($paragraph-font-styles-mobile,'small'),'fontsize');
    line-height: map-get(map-get($paragraph-font-styles-mobile,'small'),'lineheight');
    font-weight: map-get(map-get($paragraph-font-styles-mobile,'small'),'fontweight');
}

.ant-statistic-content,
.ant-statistic-content-value,
.ant-statistic-content-prefix {
    @include media-breakpoint-up(md) {
        font-size: map-get(map-get($subline-font-styles,'semi-bold'),'fontsize');
        line-height: map-get(map-get($subline-font-styles,'semi-bold'),'lineheight');
    }
    font-size: map-get(map-get($subline-font-styles-mobile,'semi-bold'),'fontsize');
    line-height: map-get(map-get($subline-font-styles-mobile,'semi-bold'),'lineheight');
}

.ant-statistic-content {
    margin-bottom: map-get($spaces, tiny);
}

.ant-statistic-content-value {
    @include media-breakpoint-up(md) {
        font-weight: map-get(map-get($subline-font-styles,'semi-bold'),'fontweight');
    }
    font-weight: map-get(map-get($subline-font-styles-mobile,'semi-bold'),'fontweight');
}

.no-margin-modal-content .ant-modal-confirm-body > .ant-modal-confirm-content {
    margin-left: 0 !important;
}
