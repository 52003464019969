@import "variables";

.ant-input,
nz-input-group {
    &.fainin-input {
        transition: all ease-in-out 0.3s; // animate width transition
        background: rgba($light-gray, .25) !important;

        padding: 4px 8px 4px 16px !important;
        height: 42px !important; // 8px y padding is added = 50px total
        border-radius: 12px !important;

        &:not(.light) {
            background: rgba($light-gray, .25) !important;
        }

        &.light {
            background: #FBFBFB !important;
        }

        input {
            background: transparent !important;
            color: var(--dark);
        }

        fa-icon {
            margin-left: 20px;
            font-size: 20px;
        }

        &.block {
            flex: 1;
        }
    }
}

/**
 * Class for a toggle switch
 */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    .switch-input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .switch-slider {
            background-color: var(--primary);
        }

        &:checked + .switch-slider:before {
            transform: translateX(20px);
        }
    }

    .switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--light-gray);
        transition: 0.4s;
        border-radius: 20px;

        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
        }
    }
}



