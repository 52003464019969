@import 'fonts';
@import 'bootstrap';
@import 'ng-zorro';
@import '@angular/cdk/overlay-prebuilt';
@import 'angled-edges/angled-edges';
@import 'swiper/swiper-bundle.css';

html {
    height: 100% !important;
    /* Fallback font matching robot to avoid layout shift */
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: 0.05px;
    word-spacing: -0.45px;
    font-weight: 300;
}
body {
    min-height: 100%;
}

:root {
    --swiper-theme-color: var(--primary);

    --tab-bar-offset: 4.75rem;
    --header-container-width: 1380px;
    --header-nav-offset: 2rem;
    --header-height: 70px;
    @include media-breakpoint-up(md) {
        --header-height: 80px;
    }
    --sub-header-height: 66px;
    --page-offset: 8px;
    --max-container-width: 1260px;
    --card-border-radius: 10px;

    // Additional colors not defined in bootstrap
    --tertiary: #40B9BC;
    --accent: #EFCD20;
    --text-color: #303030;
    --border-color: #E5E7EB;
    --light-bg: #EEFAFA;
    --white-bg: white;
    --gray-medium: #8D8D8D;
    --light-gray: #D1D5DB;
    --light-gray-bg: #F9FAFB;
    --medligh-gray: #A9A9B0;
    --orange: #FFA500;
    --google-btn-border: #1877F2;
}

@import 'variables';
$theme-colors: map-merge(
    (
        'background': $light-bg,
        'primary': $primary,
        'secondary': $secondary,
        'tertiary': $tertiary,
        'accent': $accent,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'light-gray': $light-gray,
        'dark': $dark,
        'gray': $gray,
        'gray-dark': $dark-gray,
        'gray-medium': $medium-gray,
    ),
    $theme-colors
);

@import "partials/background";
@import "partials/colors";
@import "partials/spacer";
@import "partials/spacing";
@import "partials/typography";
@import "partials/links";
@import "partials/auth";
@import "partials/collection-card";
@import "partials/featured-items";

@import "components";

// Special z-index for backdrop and overlay panel
.menudrawer-backdrop,
.menudrawer-overlay-panel {
    z-index: map-get($overlays, menudrawer) - 1; // Set your desired zIndex value here
}

//Hide scrollbar
.hide-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

//
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--white);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--secondary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
}

.highlight {
    text-decoration: underline;
    text-decoration-color: var(--accent);
}

.clickable {
    cursor: pointer;
    &-link {
        color: var(--primary);
        text-decoration: underline;
    }
}

a.disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    color: var(--light-gray) !important;
}

.scrollable-container {
    overflow: scroll;
}

.hero-swiper {
    .swiper-pagination {
        bottom: 6rem;
    }
    .swiper-pagination-bullet {
        background: white;
        opacity: 0.4;

        &.swiper-pagination-bullet-active {
            background: $primary;
            opacity: 1;
        }
    }
}

a {
    text-decoration: none;
}

.auto-width-swiper {
    .swiper-slide {
        width: auto !important;
    }
}

.smartphone-swiper {
    height: 185px;

    .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet {
            background: $light-gray !important;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: $gray !important;
            }
        }
    }
}

.border-default {
    border-radius: var(--card-border-radius);
}

#card-swiper {

    .swiper-wrapper {
        padding-bottom: 2rem;
    }

    .swiper-slide {
        height: auto;
    }

    .card {
        height: 100%;
    }
}

// pac-<element> styles are Google Places API dropdown classes
// used to style the elements to match our theme

.pac-container {
    margin-top: 0.25rem;
    border-radius: var(--card-border-radius);
    border: none;
}

.pac-item {
    border: none;
    padding: .5rem;
    font-family: Roboto, 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
}

.pac-item:first-child {
    margin-top: 1rem;
}

.pac-item-query {
    font-family: Roboto, 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 0.875rem;
}

.pac-logo {
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
}

.pac-icon {
    filter: invert(78%) sepia(32%) saturate(639%) hue-rotate(129deg) brightness(165%) contrast(100%);
}

.auto-width-swiper {
    .swiper-slide {
        width: auto !important;
    }
}

.overflow-swiper {
    .swiper {
        overflow: visible;
    }
}

.unstyled-list {
    list-style: none;
    padding-inline-start: 0;
}

.select-dropdown-field {
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    min-height: 2.625rem;
    width: 100%;
    background-color: #F9FAFB;

    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.select-dropdown-field:hover {
    background-color: var(--light-gray-bg);
}

.dropdown-content {
    border-radius: 0.5rem;

    .select-dropdown-container {
        border-radius: 0.5rem;
        height: fit-content;
        background-color: white;
        padding: 1rem;
        border: none;
        border-radius: 0.5rem;
        animation: fadeIn 0.1s ease-in forwards;
    }
}

//
// Loading
//
.loading-placeholder {
    position: relative;
    overflow: hidden;
    background-color: var(--secondary);

    &::after {
        content: '';
        display: block;
        background: $gray-300;
        opacity: 0.3;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transform: translateX(0);
        animation: 1.5s loading-placeholder ease-in-out infinite;
    }
}

// for masonry lists
.masonry-item {
    transition: top 0.4s ease-in-out, left 0.4s ease-in-out;
    width: 48%;
    margin-bottom: 0.3rem;

    @include media-breakpoint-up(md) {
        width: 32%;
    }

    @include media-breakpoint-up(xl) {
        width: 19%;
        margin-bottom: 1rem;
    }
}

//
// Header
//
.page-offset {
    padding-top: var(--page-offset);
}

.tab-bar-offset {
    margin-bottom: var(--tab-bar-offset);
}

.hero {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: $spacer $spacer 3 * $spacer;
    color: var(--light);
    background-size: cover;
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    min-height: 30vh;

    @include media-breakpoint-up(sm) {
        padding: var(--header-height) $spacer 3 * $spacer;
        background-position: bottom;
        min-height: 55vh;

        &.small {
            background-position: center;
            min-height: 35vh;
        }
    }

    .logo {
        opacity: 0.8;
        max-width: 100%;
        margin: $spacer;
    }

    .title {
        position: absolute;
        mix-blend-mode: overlay;
        color: var(--light);
        line-height: 0.85em;
        bottom: 1vh;
    }

    @media all and (min-width: 768px) {
        .scene {
            height: 400px;
        }
    }
}

//Navigation sub header
.nav-bar-link {
    font-family: "Barlow", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: var(--dark);

    &:hover {
        color: var(--primary);
    }

    &.active {
        text-decoration: underline;
        color: var(--primary);
    }
}

//
// Footer
//
.footer-link {
    color: var(--dark) !important;
    text-decoration: none;
    font-family: 'Roboto', serif;
    // Interactive elements like buttons and links should be large enough (48x48px)
    min-width: 48px;
    min-height: 48px;
}

.undo-secondary-footer-offset {
    margin-bottom: calc(-1 * map-get($spaces, base));
}

//
// Blog
//
.article-content {
    img {
        max-width: 100%;
    }
}

.editorjs-block-img {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

//
// FAQ
//
.faq-section {
    .ant-collapse-header {
        font-size: 1.25rem;

        .ant-collapse-arrow {
            font-size: 1.25rem !important;
        }
    }
}

.bring-to-front {
    z-index: 100;
}

img[data-zoom] {
    cursor: crosshair;
}

img.ng-lazyloading {
    filter: blur(5px);
}

.vsf-zoom-zoom-pane {
    box-shadow: $box-shadow-lg;
}

.notification-dot {
    background-color: var(--primary);
    color: white;
    position: absolute;
    top: 15px;
    right: 5px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 10px;
    text-align: center;
}

.cdk-global-scrollblock {
    body {
        overflow: auto;
        // position: fixed; // commenting this out because it breaks location modal on iOS
    }
}

.elevate {
    z-index: map-get($overlays, elevate) !important;
}


//Hubspot Chat button fix on mobile!
@include media-breakpoint-down(sm) {
    #hubspot-messages-iframe-container {
        // Prevent container capturing the click events
        // to delegate the event to the tabbar item instead
        pointer-events: none;

        z-index: calc(map-get($overlays, header) + 1) !important;

        #hubspot-conversations-iframe {
            pointer-events: all;
        }

        // shadow container is only active, once the chat is opened
        // whenever the chat is not opened the iframe with the button needs to be moved up!
        .hs-shadow-container:not(.active) + iframe {
            transform: translateY(calc(var(--tab-bar-offset) * -1));
        }
    }

}

fainin-authentication + fainin-secondary-footer-content {
    padding-top: 0 !important;
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-50px);
    }
}

@keyframes loading-placeholder {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}
